import React, { useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import $ from 'jquery'; // Import jQuery
import 'magnific-popup'; // Import Magnific Popup
import 'magnific-popup/dist/magnific-popup.css'; // Import Magnific Popup CSS

const Home = () => {

    useEffect(() => {
        // Initialize Magnific Popup for image galleries
        $('.popup-gallery').magnificPopup({
            delegate: '.popup-img',
            type: 'image',
            gallery: {
                enabled: true
            }
        });

        // Initialize Magnific Popup for other types of content like videos, iframes, etc.
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });
    }, []);

    return (
        <>
            <Navbar />
            <main className="main">

                <div id="carouselExampleControlsNoTouching" class="carousel slide" data-bs-touch="false" data-bs-interval="false">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="assets\img\slider\bann-1.jpg" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="assets\img\slider\bann-2.jpg" class="d-block w-100" alt="..." />
                        </div>
                        <div class="carousel-item">
                            <img src="assets\img\slider\bann-4.jpg" class="d-block w-100" alt="..." />
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>

                {/* <div className="hero-section">
                    <OwlCarousel className="hero-slider owl-carousel owl-theme" {...options}>
                        <div
                            className="hero-single"
                            style={{ background: "url(assets/img/slider/slider1.webp)" }}
                        >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-9 col-lg-9 mx-auto">
                                        <div className="hero-content text-center">
                                            <div className="hero-content-shape">
                                                <img
                                                    className="hero-content-shape-1"
                                                    src="assets/img/shape/06.png"
                                                    alt=""
                                                />
                                                <img
                                                    className="hero-content-shape-2"
                                                    src="assets/img/shape/11.png"
                                                    alt=""
                                                />
                                            </div>
                                            <h6
                                                className="hero-sub-title"
                                                data-animation="fadeInUp"
                                                data-delay=".25s"
                                            >
                                                We Are Skilled &amp; Expert
                                            </h6>
                                            <h1
                                                className="hero-title"
                                                data-animation="fadeInUp"
                                                data-delay=".50s"
                                            >
                                                Wedding &amp; Event Planner For <span>Colorful</span> Couples
                                            </h1>
                                            <p data-animation="fadeInUp" data-delay=".75s">
                                                There are many variations of passages orem psum available but the
                                                majority have suffered alteration in some form by injected humour
                                                or randomised.
                                            </p>
                                            <div
                                                className="hero-btn mx-auto"
                                                data-animation="fadeInUp"
                                                data-delay=".75s"
                                            >
                                                <a href="#" className="theme-btn">
                                                    About More
                                                    <i className="far fa-arrow-right" />
                                                </a>
                                                <a href="#" className="theme-btn theme-btn2">
                                                    Learn More
                                                    <i className="far fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="hero-single"
                            style={{ background: "url(assets/img/slider/slider3.webp)" }}
                        >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-9 col-lg-9 mx-auto">
                                        <div className="hero-content text-center">
                                            <div className="hero-content-shape">
                                                <img
                                                    className="hero-content-shape-1"
                                                    src="assets/img/shape/06.png"
                                                    alt=""
                                                />
                                                <img
                                                    className="hero-content-shape-2"
                                                    src="assets/img/shape/11.png"
                                                    alt=""
                                                />
                                            </div>
                                            <h6
                                                className="hero-sub-title"
                                                data-animation="fadeInUp"
                                                data-delay=".25s"
                                            >
                                                We Are Skilled &amp; Expert
                                            </h6>
                                            <h1
                                                className="hero-title"
                                                data-animation="fadeInUp"
                                                data-delay=".50s"
                                            >
                                                Wedding &amp; Event Planner For <span>Colorful</span> Couples
                                            </h1>
                                            <p data-animation="fadeInUp" data-delay=".75s">
                                                There are many variations of passages orem psum available but the
                                                majority have suffered alteration in some form by injected humour
                                                or randomised.
                                            </p>
                                            <div
                                                className="hero-btn mx-auto"
                                                data-animation="fadeInUp"
                                                data-delay=".75s"
                                            >
                                                <a href="#" className="theme-btn">
                                                    About More
                                                    <i className="far fa-arrow-right" />
                                                </a>
                                                <a href="#" className="theme-btn theme-btn2">
                                                    Learn More
                                                    <i className="far fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="hero-single"
                            style={{ background: "url(assets/img/slider/slider2.webp)" }}
                        >
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-md-9 col-lg-9 mx-auto">
                                        <div className="hero-content text-center">
                                            <div className="hero-content-shape">
                                                <img
                                                    className="hero-content-shape-1"
                                                    src="assets/img/shape/06.png"
                                                    alt=""
                                                />
                                                <img
                                                    className="hero-content-shape-2"
                                                    src="assets/img/shape/11.png"
                                                    alt=""
                                                />
                                            </div>
                                            <h6
                                                className="hero-sub-title"
                                                data-animation="fadeInUp"
                                                data-delay=".25s"
                                            >
                                                We Are Skilled &amp; Expert
                                            </h6>
                                            <h1
                                                className="hero-title"
                                                data-animation="fadeInUp"
                                                data-delay=".50s"
                                            >
                                                Wedding &amp; Event Planner For <span>Colorful</span> Couples
                                            </h1>
                                            <p data-animation="fadeInUp" data-delay=".75s">
                                                There are many variations of passages orem psum available but the
                                                majority have suffered alteration in some form by injected humour or
                                                randomised.
                                            </p>
                                            <div
                                                className="hero-btn mx-auto"
                                                data-animation="fadeInUp"
                                                data-delay=".75s"
                                            >
                                                <a href="#" className="theme-btn">
                                                    About More
                                                    <i className="far fa-arrow-right" />
                                                </a>
                                                <a href="#" className="theme-btn theme-btn2">
                                                    Learn More
                                                    <i className="far fa-arrow-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div> */}

                <div className="about-area pt-5 pb-5">
                    <div className="about-shape">
                        <img className="about-shape-1" src="assets/img/shape/04.png" alt="" />
                        <img className="about-shape-2" src="assets/img/shape/05.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div
                                    className="about-left wow fadeInLeft"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="about-img">
                                        <img src="
                                        assets\img\slider\foundation.jpeg" alt="" />
                                    </div>
                                    <div className="about-experience">
                                        <h1>
                                            25 <span>+</span>
                                        </h1>
                                        <span className="about-experience-text">Years Of Experience</span>
                                    </div>
                                    <div className="about-img-shape">
                                        <img src="assets/img/shape/01.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div
                                    className="about-right wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="site-heading mb-3">
                                        <span className="site-title-tagline">About Us</span>
                                        <h2 className="site-title">
                                            We Make Your Every <span>Moment</span> Special
                                        </h2>

                                    </div>
                                    <p className="about-text">
                                        At Gurunanak Lawn, we understand that life's most precious moments deserve to be celebrated in a setting that is as exquisite as the occasion itself. Nestled amidst lush greenery and surrounded by nature's beauty, our sprawling wedding lawn sets the perfect stage for your dream event.
                                    </p>
                                    <div className="about-list-wrapper">
                                        <ul className="about-list list-unstyled">
                                            <li>
                                                <div className="about-icon">
                                                    <span className="fas fa-check-circle" />
                                                </div>
                                                <div className="about-list-text">
                                                    <p>Experienced Event Planning: Skilled planners ensure meticulous attention and personalized service.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="about-icon">
                                                    <span className="fas fa-check-circle" />
                                                </div>
                                                <div className="about-list-text">
                                                    <p>Professional Staff: Dedicated team ensures smooth event execution, letting you enjoy every moment.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="about-icon">
                                                    <span className="fas fa-check-circle" />
                                                </div>
                                                <div className="about-list-text">
                                                    <p>Versatile Event Spaces: Our lawn adapts to weddings, receptions, engagements, and birthdays, ensuring a memorable experience for all.</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="about-bottom">
                                        <a href="/about" className="theme-btn">
                                            Read More <i className="far fa-arrow-right" />
                                        </a>
                                        {/* <div className="about-call">
                                            <div className="about-call-icon">
                                                <i className="fal fa-user-headset" />
                                            </div>
                                            <div className="about-call-content">
                                                <span>Call Us Anytime</span>
                                                <h5 className="about-call-number">
                                                    <a href="tel:+2123654789">+2 123 654 789</a>
                                                </h5>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="service-area bg pt-5">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto wow fadeInDown"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                    <span className="site-title-tagline">What We Do</span>
                                    <h2 className="site-title">
                                        Our Awesome <span>Services</span>
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OwlCarousel
                            className="service-slider owl-carousel owl-theme wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".25s"
                            autoplay
                            autoplayTimeout={2000}
                            autoplayHoverPause
                            items={3}
                            loop
                            center={false}
                            rewind={false}
                            mouseDrag
                            touchDrag
                            pullDrag
                            freeDrag={false}
                            margin={0}
                            stagePadding={0}
                            merge={false}
                            mergeFit
                            autoWidth={false}
                            startPosition={0}
                            rtl={false}
                            smartSpeed={250}
                            fluidSpeed={false}
                            dragEndSpeed={false}
                            responsive={{
                                0: {
                                    items: 1
                                },
                                480: {
                                    items: 2,
                                    nav: false
                                },
                                768: {
                                    items: 3,
                                    loop: false
                                }
                            }}
                        >
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="assets\img\slider\service-1.webp" style={{ padding: "21px" }} alt="" />
                                </div>
                                <div className="service-icon">
                                    <i className="flaticon-wedding-rings" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Wedding</a>
                                    </h3>
                                    <p className="service-text">
                                        Let us turn your dream wedding into a reality amidst our picturesque lawn and impeccable service.
                                    </p>
                                    <div className="service-arrow">
                                        <a href="/wedding" className="service-btn">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="assets\img\slider\service-2.webp" style={{ padding: "21px" }} alt="" />
                                </div>
                                <div className="service-icon">
                                    <i className="flaticon-video-camera" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="/reception">Reception</a>
                                    </h3>
                                    <p className="service-text">
                                        Host a memorable reception surrounded by nature's beauty and personalized touches that reflect your style.
                                    </p>
                                    <div className="service-arrow">
                                        <a href="/reception" className="service-btn">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="assets\img\slider\service-3.webp" style={{ padding: "21px" }} alt="" />
                                </div>
                                <div className="service-icon">
                                    <i className="flaticon-wedding-invitation" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="/engagement">Engagement</a>
                                    </h3>
                                    <p className="service-text">
                                        Begin your journey together with an intimate and romantic celebration tailored to your unique love story.
                                    </p>
                                    <div className="service-arrow">
                                        <a href="/engagement" className="service-btn">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="assets\img\slider\service-4.webp" style={{ padding: "21px" }} alt="" />
                                </div>
                                <div className="service-icon">
                                    <i className="flaticon-wedding-invitation-1" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="/renewing">Renewing your Vows</a>
                                    </h3>
                                    <p className="service-text">
                                        Your Vows: Rediscover the magic of your relationship with a heartfelt vow renewal ceremony in our serene setting.
                                    </p>
                                    <div className="service-arrow">
                                        <a href="/renewing" className="service-btn">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="assets\img\slider\service-5.webp" style={{ padding: "21px" }} alt="" />
                                </div>
                                <div className="service-icon">
                                    <i className="flaticon-balloon" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="/birthday">Birthday Parties</a>
                                    </h3>
                                    <p className="service-text">
                                        Celebrate year of life with a one-of-a-kind birthday bash that will leave your guests amazed and delighted.
                                    </p>
                                    <div className="service-arrow">
                                        <a href="/birthday" className="service-btn">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="service-item">
                                <div className="service-img">
                                    <img src="assets\img\slider\service-6.webp" style={{ padding: "21px" }} alt="" />
                                </div>
                                <div className="service-icon">
                                    <i className="flaticon-wedding-car" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="/haldi">Haldi Ceremony</a>
                                    </h3>
                                    <p className="service-text">
                                        Embrace tradition and joy with a vibrant haldi ceremony filled with laughter and love.
                                    </p>
                                    <div className="service-arrow">
                                        <a href="/haldi" className="service-btn">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div> {/* Slider which is not responsive accourding to mobile view */}

                <div className='choose-area pt-5 pb-5'>
                    <div className="container">
                        <div className="row">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Occasions</span>
                                <h2 className="site-title">
                                    Why Choose <span>Us</span>
                                </h2>
                                <div className="header-img-divider">
                                    <img src="assets/img/icon/divider.png" alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p style={{ textAlign: "justify" }} className="pt-1"> <span className='fw-bold text-dark fs-5' style={{ fontFamily: "math" }}>Our meticulously maintained lawn: </span>  offers a picturesque backdrop for your celebrations, creating a serene and enchanting atmosphere.
                                    Personalized Service: From initial planning to the final moments of your event, our dedicated team will work closely with you to ensure every detail is executed flawlessly. <br />
                                    <span className='fw-bold text-dark fs-5' style={{ fontFamily: "math" }}>Flexible Packages:</span> We offer a range of customizable packages to suit your specific needs and budget, allowing you to create the perfect event without compromising on quality.
                                    Exceptional Amenities: From state-of-the-art sound systems to elegant décor options, we provide everything you need to create a truly unforgettable experience for you and your guests.
                                    At Gurunanak Lawn, we take pride in helping you create memories that will last a lifetime. Contact us today to start planning your dream event!</p>
                            </div>
                            <div className="col-md-6">
                                <img src="assets\img\slider\wedd-1.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pricing-area bg pt-5 pb-5">
                    <div className="pricing-shape">
                        <img src="assets/img/shape/07.png" alt="" />
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div
                                className="col-lg-7 mx-auto wow fadeInDown"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                <span className="site-title-tagline">What We Do</span>
                                    <h2 className="site-title">
                                    Our <span>Collaboration</span>
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="pricing-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="pricing-item-shape">
                                        <img src="assets/img/shape/06.png" className='opacity-25' alt="" />
                                    </div>
                                    <div className="text">
                                        <h3 className='pb-2'>Catering</h3>
                                        <p style={{ textAlign: "justify" }}>Our catering service offers customized menus with fresh ingredients and expert chefs, ensuring an extraordinary dining experience. We focus on elegant presentation, attentive service, and strict health and safety standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="pricing-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".50s"
                                >
                                    <div className="pricing-item-shape">
                                        <img src="assets/img/shape/06.png" className='opacity-25' alt="" />
                                    </div>
                                    <div className="text">
                                    <h3 className='pb-2'>Photography</h3>
                                        <p style={{ textAlign: "justify" }}>Our photography service captures your special moments with professional photographers, the latest equipment, and personalized packages. We ensure every important memory is beautifully preserved with our creative.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="pricing-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".75s"
                                >
                                    <div className="pricing-item-shape">
                                        <img src="assets/img/shape/06.png" className='opacity-25' alt="" />
                                    </div>
                                    <div className="text">
                                        <h3 className='pb-2'>Venue Decoration</h3>
                                        <p style={{ textAlign: "justify" }}>At Gurunanak Lawn, we personalize your event with stunning themes, floral arrangements, and lighting to match your vision. Our skilled team designs custom decor and innovative layouts to create a breathtaking setting.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="pricing-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="pricing-item-shape">
                                        <img src="assets/img/shape/06.png" className='opacity-25' alt="" />
                                    </div>
                                    <div className="text">
                                        <h3 className='pb-2'>Bridal Services</h3>
                                        <p style={{ textAlign: "justify" }}>Our bridal suite offers luxury and tranquility, providing the perfect space for you to prepare for your big day. Our team of expert artists are committed to enhancing your natural beauty and ensuring you feel confident on your wedding day.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gallery-area pt-5">
                    <div className="gallery-shape">
                        <img className="gallery-shape-1" src="assets/img/shape/02.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto wow fadeInDown"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                    <span className="site-title-tagline">Gallery</span>
                                    <h2 className="site-title">
                                        Our Captured <span>Moments</span>
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row popup-gallery wow fadeInUp"
                            data-wow-duration="1s"
                            data-wow-delay=".25s"
                            data-masonry='{"percentPosition": true }'
                        >
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-1.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-1.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-3.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-3.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-2.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-2.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-4.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-4.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-6.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-6.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-5.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-5.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-7.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-7.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-8.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-8.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="gallery-item">
                                    <div className="gallery-img">
                                        <img src="assets\img\slider\service-4.webp" alt="" />
                                    </div>
                                    <div className="gallery-content">
                                        <a
                                            className="popup-img gallery-link"
                                            href="assets\img\slider\service-4.webp"
                                        >
                                            <i className="far fa-plus" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Old Gallery End */}

                <div className="counter-area">
                    <div className="container-fluid">
                        <div className="counter-wrapper">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="counter-box">
                                        <div className="icon">
                                            <i className="flaticon-suitcase" />
                                        </div>
                                        <div>
                                            <span
                                                className="counter"
                                                data-count="+"
                                                data-to={1200}
                                                data-speed={3000}
                                            >
                                                2200
                                            </span>
                                            <h6 className="title">+ Wedding Done </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="counter-box">
                                        <div className="icon">
                                            <i className="flaticon-wedding-arch" />
                                        </div>
                                        <div>
                                            <span
                                                className="counter"
                                                data-count="+"
                                                data-to={500}
                                                data-speed={3000}
                                            >
                                                4800
                                            </span>
                                            <h6 className="title">+ Happy Clients</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="counter-box">
                                        <div className="icon">
                                            <i className="flaticon-placeholder" />
                                        </div>
                                        <div>
                                            <span
                                                className="counter"
                                                data-count="+"
                                                data-to={50}
                                                data-speed={3000}
                                            >
                                                2000
                                            </span>
                                            <h6 className="title">+ Engagements Done</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="counter-box">
                                        <div className="icon">
                                            <i className="flaticon-pastor" />
                                        </div>
                                        <div>
                                            <span
                                                className="counter"
                                                data-count="+"
                                                data-to={1500}
                                                data-speed={3000}
                                            >
                                                25
                                            </span>
                                            <h6 className="title">+ Year of Experience</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="team-area bg p-5">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto wow fadeInDown"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                    <span className="site-title-tagline">Our Planners</span>
                                    <h2 className="site-title">
                                        Meet Our <span>Planners</span>
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="team-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="team-img">
                                        <img src="assets/img/team/01.jpg" alt="thumb" />
                                    </div>
                                    <div className="team-content">
                                        <div className="team-bio">
                                            <h5>
                                                <a href="#">Edna Craig</a>
                                            </h5>
                                            <span>Planner</span>
                                        </div>
                                        <div className="team-social">
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="team-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".50s"
                                >
                                    <div className="team-img">
                                        <img src="assets/img/team/02.jpg" alt="thumb" />
                                    </div>
                                    <div className="team-content">
                                        <div className="team-bio">
                                            <h5>
                                                <a href="#">Jeffrey Cox</a>
                                            </h5>
                                            <span>CEO &amp; Founder</span>
                                        </div>
                                        <div className="team-social">
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="team-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".75s"
                                >
                                    <div className="team-img">
                                        <img src="assets/img/team/03.jpg" alt="thumb" />
                                    </div>
                                    <div className="team-content">
                                        <div className="team-bio">
                                            <h5>
                                                <a href="#">Audrey Gaddis</a>
                                            </h5>
                                            <span>Makeup Artist</span>
                                        </div>
                                        <div className="team-social">
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div
                                    className="team-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay="1s"
                                >
                                    <div className="team-img">
                                        <img src="assets/img/team/04.jpg" alt="thumb" />
                                    </div>
                                    <div className="team-content">
                                        <div className="team-bio">
                                            <h5>
                                                <a href="#">Rodger Garza</a>
                                            </h5>
                                            <span>Photographer</span>
                                        </div>
                                        <div className="team-social">
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */} {/* planner Section  */}

                <div className="quote-area pt-5 pb-5">
                    <div className="container">
                        <div className="quote-wrapper">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="quote-img">
                                        <img src="assets/img/quote/01.png" alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="quote-content shadow-lg">
                                        <div className="quote-header">
                                            <h6>Get Quote</h6>
                                            <h2>Feel Free To Contact With Us</h2>
                                        </div>
                                        <form action="#">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            className="form-control"
                                                            placeholder="Your Name"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Email Address"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="subject"
                                                            className="form-control"
                                                            placeholder="Subject"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <select className="form-select" name="service">
                                                            <option value="">Choose Service</option>
                                                            <option value={1}>Wedding</option>
                                                            <option value={2}>Reception Your Vows</option>
                                                            <option value={3}>Engagement</option>
                                                            <option value={4}>Renewing</option>
                                                            <option value={5}>Birthday Parties</option>
                                                            <option value={6}>Haldi-Ceremony</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <textarea
                                                    name="message"
                                                    className="form-control"
                                                    placeholder="Type Message"
                                                    rows={4}
                                                    defaultValue={""}
                                                />
                                            </div>
                                            <button className="theme-btn">
                                                Submit Now
                                                <i className="far fa-arrow-right" />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="testimonial-area bg pt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto text-center"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                    <span className="site-title-tagline">Testimonials</span>
                                    <h2 className="site-title">
                                        What Our Client <span>Say's</span>
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="testimonial-single item shadow-lg">
                                    <div className="testimonial-shape">
                                        <img src="assets/img/shape/09.png" alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets\img\slider\risha.png" alt="" />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Rishabh Paliya</h4>
                                            <p>Clients</p>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <p>
                                            Nice and humongous place for marriage party function , nice ambience from decoration to space everything was fine and the location was also easily available for search in google map.
                                        </p>
                                        <div className="testimonial-quote-icon">
                                            <i className="flaticon-straight-quotes" />
                                        </div>
                                    </div>
                                    <div className="testimonial-rate">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="testimonial-single item shadow-lg">
                                    <div className="testimonial-shape">
                                        <img src="assets/img/shape/09.png" alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets\img\slider\unnamed.png" alt="" />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Praveen Singh</h4>
                                            <p>Clients</p>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <p>
                                            It was nice lawn. Staff was co -operative. All services were good. Management was good. Spacious for 400 to 500 person. Proper arrangement for sitting and eating.
                                        </p>
                                        <div className="testimonial-quote-icon">
                                            <i className="flaticon-straight-quotes" />
                                        </div>
                                    </div>
                                    <div className="testimonial-rate">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="testimonial-single item shadow-lg">
                                    <div className="testimonial-shape">
                                        <img src="assets/img/shape/09.png" alt="" />
                                    </div>
                                    <div className="testimonial-content">
                                        <div className="testimonial-author-img">
                                            <img src="assets\img\slider\Manish.png" alt="" />
                                        </div>
                                        <div className="testimonial-author-info">
                                            <h4>Manish Gupta</h4>
                                            <p>Clients</p>
                                        </div>
                                    </div>
                                    <div className="testimonial-quote">
                                        <p>
                                            Very spacious place which can hold lots of people. As there are no traffic lights and very less crowd that side so they have placed a guard. Overall good and easy to reach.
                                        </p>
                                        <div className="testimonial-quote-icon">
                                            <i className="flaticon-straight-quotes" />
                                        </div>
                                    </div>
                                    <div className="testimonial-rate">
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                        <i className="fas fa-star" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* Slider Section */}

                <div className="video-area">
                    <div className="container-fluid px-0">
                        <div
                            className="video-content"
                            style={{ backgroundImage: "url(https://img.freepik.com/premium-photo/autumn-background-with-colored-leaves-white-wooden-board_98438-1479.jpg?w=826)" }}
                        >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="cta-text">
                                        <h1 style={{ marginTop: "8rem", color: "#06002e" }} className='text-center'>Where Dreams Meet Nature  <br />  Celebrate Life's Moments at Gurunanak Lawn</h1>
                                        <p className='text-center' style={{ marginBottom: "9rem", color: "#06002e" }}>
                                            Ready to plan your dream event at Gurunanak Lawn? We’re here to help turn your vision into a beautiful reality.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className="blog-area p-5">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-lg-6 mx-auto wow fadeInDown"
                                data-wow-duration="1s"
                                data-wow-delay=".25s"
                            >
                                <div className="site-heading text-center">
                                    <span className="site-title-tagline">Our Blog</span>
                                    <h2 className="site-title">
                                        Our Latest <span>News &amp; Blog</span>{" "}
                                    </h2>
                                    <div className="header-img-divider">
                                        <img src="assets/img/icon/divider.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div
                                    className="blog-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".25s"
                                >
                                    <div className="blog-item-img">
                                        <img src="assets/img/blog/01.jpg" alt="Thumb" />
                                    </div>
                                    <div className="blog-item-info">
                                        <div className="blog-item-meta">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-user-circle" /> By Alicia Davis
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-calendar-alt" /> May 12, 2023
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 className="blog-title">
                                            <a href="#">
                                                There are many variations of the passages available suffered
                                            </a>
                                        </h4>
                                        <a className="theme-btn" href="#">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div
                                    className="blog-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".50s"
                                >
                                    <div className="blog-item-img">
                                        <img src="assets/img/blog/02.jpg" alt="Thumb" />
                                    </div>
                                    <div className="blog-item-info">
                                        <div className="blog-item-meta">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-user-circle" /> By Alicia Davis
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-calendar-alt" /> May 12, 2023
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 className="blog-title">
                                            <a href="#">
                                                There are many variations of the passages available suffered
                                            </a>
                                        </h4>
                                        <a className="theme-btn" href="#">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div
                                    className="blog-item wow fadeInUp"
                                    data-wow-duration="1s"
                                    data-wow-delay=".75s"
                                >
                                    <div className="blog-item-img">
                                        <img src="assets/img/blog/03.jpg" alt="Thumb" />
                                    </div>
                                    <div className="blog-item-info">
                                        <div className="blog-item-meta">
                                            <ul>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-user-circle" /> By Alicia Davis
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <i className="far fa-calendar-alt" /> May 12, 2023
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <h4 className="blog-title">
                                            <a href="#">
                                                There are many variations of the passages available suffered
                                            </a>
                                        </h4>
                                        <a className="theme-btn" href="#">
                                            Read More
                                            <i className="far fa-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="instagram-area">
                    <div className="container-fluid px-0">
                        <OwlCarousel className="instagram-slider owl-carousel owl-theme
                        autoplay
                        autoplayTimeout={2000}
                        autoplayHoverPause
                        items={3}
                        loop
                        center={false}
                        rewind={false}
                        mouseDrag
                        touchDrag
                        pullDrag
                        freeDrag={false}
                        margin={0}
                        stagePadding={0}
                        merge={false}
                        mergeFit
                        autoWidth={false}
                        startPosition={0}
                        rtl={false}
                        smartSpeed={250}
                        fluidSpeed={false}
                        dragEndSpeed={false}
                        responsive={{
                            0: {
                                items: 1
                            },
                            480: {
                                items: 2,
                                nav: false
                            },
                            768: {
                                items: 3,
                                loop: false
                            }
                        }}">
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/01.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/02.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/03.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/04.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/05.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/06.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                            <div className="instagram-item">
                                <div className="instagram-img">
                                    <img src="assets/img/instagram/07.jpg" alt="Thumb" />
                                    <a href="#">
                                        <i className="fab fa-instagram" />
                                    </a>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                </div> */}
                {/* Slider Section */}
            </main>
            <Footer />
        </>
    )
}

export default Home